<template>

  <div class="wrap">
    <div class="top-box">
      <h3 class="name">
        {{ townName }}
      </h3>
      <div class="goback-img-box" @click="this.$router.back()">
        <img src="/images/album/goback.png" class="goback-img">
      </div>
    </div>
    <div id="waterfall" ref="waterfall" >
      <div class="img-box default-card-animation" v-for="(item, index) in imgsArr_c" :key="index"
           :style="{width: imgWidth+  'px', height: item._height + 'px'}" ref="imgBox" @click="toggleVisible(index,this.imgsArr_c)">
        <img :data-src="item.picture" />
        <div class="item-bottom-box">
          <p class="desc-text">
            {{item.title }}
          </p>
          <div class="acount">
            <img src="/images/album/eye.png" class="eye-img"/>
            <span>{{item.readNums}}</span>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="visible" :footer="null" :bodyStyle="{ padding: 0 }"
             :mask="true"
             :maskStyle="{'background':'rgba(255,255,255,0.5)'}"
             :closable="false"
             :style="{width: '720px',height: '720px'}"
    >
      <div class="model-box">
        <img :src="imgsArr_c[showIndex]?.picture" class="mode-img"/>
        <div class="model-detail-box">
          <span class="desc-text">
           {{imgsArr_c[showIndex].title}}
          </span>
          <div class="model-right-box">
            <img src="/images/album/eye.png" class="model-eye-img"/>
            <span class="model-acount">{{imgsArr_c[showIndex].readNums}}</span>
          </div>
        </div>
      </div>
    </a-modal>
    <Nodata :visible="imgsArr.length<=0"/>
  </div>
</template>
<script>
import {useRoute} from 'vue-router'
import {onBeforeMount, onUnmounted, ref} from 'vue'

import api from '@/api'
import Nodata from '@/components/common/nodata'

export default {
  name: 'album-detail',
  components: {Nodata},

  data () {
    return {
      imgsArr: [
      ],
      imgsArr_c: [], // 渲染的图片
      imgCol: 4, // 图片列数
      imgGap: 0, // 图片间间隔
      loadedCount: 0,
      imgBoxEls: [], // 所有 img-box 元素
      beginIndex: 0,
      colsHeightArr: [], // 保存当前每一列的高度
      reachBottomDistance: 20, // 滚动触底距离，触发加载新图片
      viewHeight: 0, // 窗口视图大小
    }
  },
  computed: {
    isMobile () {
      return false
    },
    // 容器 waterfall 的宽度
    waterfallWidth () {
      return this.$refs['waterfall'].clientWidth
    },
    // 图片宽度
    imgWidth () {
      return this.colWidth - 2 * this.imgGap
    },
    // 列宽度
    colWidth () {
      return this.waterfallWidth / this.colNum
    },
    // 列数
    colNum () {
      return this.isMobile ? 2 : this.imgCol
    }
  },
  watch: {
    imgsArr (newVal) {
      if (this.imgsArr_c.length > newVal.length || (this.imgsArr_c.left > 0 && newVal[0] && !newVal[0]._height))
        this.reset()
      this.preLoad()
    }
  },
  methods: {
    // 预加载 设置图片宽高
    preLoad () {
      // forEach 无法通过 item 直接修改数组元素，需用数组下标修改
      this.imgsArr.forEach((item, index) => {
        if (index < this.loadedCount)
          return
        if (!item.picture) {
          this.imgsArr[index]._height = '0'
          ++this.loadedCount
          if (this.imgsArr.length === this.loadedCount) {
            this.preloaded()
          }
        } else {
          let img = new Image()
          img.src = item.picture
          img.onload = img.onerror = (e) => {
            // 若加载失败则设置图片高度与宽度一致，加载成功则动态计算图片高度
            this.imgsArr[index]._height = (e.type === 'load' ? Math.round(this.imgWidth * (img.height / img.width)) : this.imgWidth)+50
            if (e.type === 'error') {
              this.imgsArr[index]._error = true
            }
            ++this.loadedCount
            if (this.imgsArr.length === this.loadedCount) {
              this.preloaded()
            }
          }
        }
      })
    },
    preloaded () {
      this.imgsArr_c = [].concat(this.imgsArr)
      this.$nextTick(() => {
        this.waterfall()
      })
    },
    // waterfall 布局
    waterfall () {
      // 等到整个视图都渲染完毕再执行
      this.imgBoxEls = this.$refs['imgBox']
      if (!this.imgBoxEls)
        return
      let top, left, height
      if (this.beginIndex === 0)
        this.colsHeightArr = []
      for (let i = this.beginIndex; i < this.imgBoxEls.length; ++i) {
        if (!this.imgBoxEls[i])
          return
        height = this.imgBoxEls[i].offsetHeight
        // 第一行
        if (i < this.colNum) {
          this.colsHeightArr.push(height)
          top = 0
          left = i * this.colWidth
        } else {
          // 找到最低的高度和其索引
          let minHeight = Math.min.apply(null, this.colsHeightArr)
          let minIdx = this.colsHeightArr.indexOf(minHeight)
          top = minHeight
          left = minIdx * this.colWidth
          this.colsHeightArr[minIdx] += height
        }
        // 设置 img-box 位置
        this.imgBoxEls[i].style.top = top + 'px'
        this.imgBoxEls[i].style.left = left + 'px'
        // 当前图片在窗口内，则加载
        if (top < this.viewHeight) {
          let imgEl = this.imgBoxEls[i].children[0]
          imgEl.src = imgEl.getAttribute('data-src')
          imgEl.style.opacity = 1
          imgEl.style.transform = 'scale(1)'

          this.imgBoxEls[i].children[1].style.opacity = 1

        }
      }
      this.beginIndex = this.imgBoxEls.length
    },
    reset () {
      this.imgsArr_c = []
      this.beginIndex = 0
      this.loadedCount = 0
    },
    // 滚动触底事件
    scrollFn () {

      let minHeight = Math.min.apply(null, this.colsHeightArr)
      // 滚动条滚动的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

      // 到达最底层的高度最低的一列，则触发 handleReachBottom 方法
      if (
          scrollTop + this.viewHeight >
          minHeight - this.reachBottomDistance
      ) {
        this.handleReachBottom()
      }
      // 图片懒加载
      this.imgBoxEls.forEach((imgBoxEl) => {
        let imgEl = imgBoxEl.children[0]
        // console.log('-----imgBoxEl---',imgBoxEl.children[1]);
        // 若已加载，则跳过
        if (imgEl.src)
          return
        // 当前图片所处的高度
        let top = imgBoxEl.style.top
        top = Number.parseFloat(top.slice(0, top.length - 2))
        // 图片已到达可视范围，则加载
        if (scrollTop + this.viewHeight > top) {
          imgEl.src = imgEl.getAttribute('data-src')
          imgEl.style.opacity = 1
          imgEl.style.transform = 'scale(1)'
          imgBoxEl.children[1].style.opacity = 1

        }
      })
    },
    scroll () {
      window.onscroll = this.throttle(this.scrollFn, 500)
    },
    handleReachBottom () {
      this.imgsArr = this.imgsArr.concat(this.imgsArr)

    },
    // 节流函数
    throttle (fn, time) {
      let canRun = true
      return function () {
        if (!canRun)
          return
        canRun = false
        setTimeout(() => {
          fn.apply(this)
          canRun = true
        }, time)
      }
    },
    async getData(){

      let res = await api.townAblum({
        townId: this.$route.query.tid || this.$route.query.id,
        pageNum: 1,
        pageSize: 9999
      })

      this.imgsArr = res;
      // console.log('--getData-',res);
    }
  },
  mounted () {
    this.viewHeight = (document.documentElement.clientHeight == 0) ? document.body.clientHeight : document.documentElement.clientHeight
    this.preLoad()
    // this.scroll()
    this.getData();
  },


  setup () {
    onBeforeMount(() => {
      document.querySelector('body').style.background = '#E5E5E5'
    })
    onUnmounted(() => {
      document.querySelector('body').style.background = '#ffffff'
    })


    const router = useRoute()
    const index = ref(0)
    const list = ref([])
    const visible = ref(false)
    const showIndex = ref(0);
    const townName = ref(router.query.name|| '');

    const toggleVisible = (index,data) => {
      showIndex.value = index;
      visible.value = !visible.value

      read(data[index].id);
    }

    const changeIndex = num => {
      index.value = num
    }
    const getList = async () => {
      let res = await api.townAblum({
        townId: router.query.tid ||  router.query.id,

      })
      list.value = res
    }

    const read = async (aid) => {
       await api.townAblumRead({
        id:aid
      })
    }


    return {
      index,
      list,
      changeIndex,
      getList,
      visible,
      toggleVisible,
      showIndex,
      read,
      townName
    }
  },

}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.wrap {
  height: 100%;
  background: #E5E5E5;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

}

#waterfall {
  width: 12rem;
  position: relative;

  @keyframes show-card {
    0% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }

  .img-box {
    position: absolute;
    border-radius: 0;
    padding: 5px 5px 5px 5px;


    img {
      width: 100%;
      opacity: 0;
      transform: scale(0.5);
      transition: all 0.6s;
      transition-delay: 0.1s;

    }
  }

  .item-bottom-box {
    background: #ffffff;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    opacity: 0;
    .desc-text {
      color:#333333;
      font-size: 14px;
      flex: 1;
      text-align: left;
      @include text-moreLine(1)
    }
    .eye-img {
      width: 30px;
      height: 22px;
      opacity:1;
    }
    .acount {
     color: #999999;
      font-size: 12px;
    }
  }

  /* .default-card-animation {
    animation: show-card 0.4s;
    transition: left 0.6s top 0.6s;
    transition-delay: 0.1s;
  } */

}


.top-box {
  display: flex;
  width: 12rem;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.3rem;

  .name {
    font-size: 0.3rem;
    color: #000000;
    font-weight: 500;
  }

  .goback-img-box {
    background: white;
    width: 0.4rem;
    height: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .goback-img {
    width: 0.13rem;
    height: 0.15rem;
  }

}
.model-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);

  .mode-img {
    max-height: 500px;
    max-width: 700px;
    //padding: 0 0.1rem;
    padding-top: 0.2rem;
  }

  .model-detail-box {
    display: flex;
    padding: 0.2rem;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .model-right-box {
    margin-right: 0;
    display: flex;
    align-items: center;
    .model-eye-img {
      width: 0.17rem;
      height: 0.13rem;
    }
    .model-acount {
      color: #999999;
      font-size: 0.16rem;
    }
  }



}

</style>
